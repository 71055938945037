import React from "react"
import Grid from "../../components/Grid/Grid"
import Tile from "../../components/Tile/Tile"
import { Link, graphql } from "gatsby"

export default ({ data }) => {
  const gridItems = data.allMarkdownRemark.nodes.map(content => {
    const sources = [
      content.frontmatter.image.childImageSharp.mobile,
      {
        ...content.frontmatter.image.childImageSharp.desktop,
        media: `(min-width: 900px)`,
      },
    ]

    return (
      <Link to={content.fields.slug} key={content.frontmatter.title}>
        <Tile image={sources} title={content.frontmatter.title} />
      </Link>
    )
  })

  return (
    <div>
      <h1>Szolgáltatások</h1>
      <Grid gridItems={gridItems} columns={4} />
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "service-page" }
          category: { in: "services" }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              desktop: fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
              mobile: fluid(maxWidth: 250, maxHeight: 250, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
